"use strict";

var _interopRequireDefault = require("D:/www/xinshengdai/xiehui/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ThemePicker: _ThemePicker.default
  },
  data: function data() {
    return {};
  },
  computed: {
    tagsView: {
      get: function get() {
        return this.$store.state.setting.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('setting/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.setting.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('setting/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.dispatch('app/changeTheme', val);
    }
  }
};
exports.default = _default;