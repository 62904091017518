var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex align-center justify-center",
    },
    [
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "title" }, [_vm._v("创建校区")]),
        _c("div", { staticClass: "tip" }, [_vm._v("请为你的学校创建一家校区")]),
        _c("div", { staticClass: "line" }),
        _c(
          "div",
          { staticClass: "margin-top" },
          [
            _vm._l(_vm.owner, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "owner-item",
                  on: {
                    click: function ($event) {
                      return _vm.posLogin(item.id)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex align-center" }, [
                    _c("img", {
                      attrs: { src: item.image && item.image.url, alt: "" },
                    }),
                    _c("div", { staticClass: "owner-name" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                  _vm._m(0, true),
                ]
              )
            }),
            _c(
              "div",
              {
                staticClass: "owner-item owner-create",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              },
              [_vm._m(1), _vm._m(2)]
            ),
          ],
          2
        ),
        !_vm.user.is_owner && !_vm.owner.length
          ? _c("div", { staticClass: "tip margin-top" }, [
              _vm._v("*请联系管理员分配校区与权限"),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { width: "fit-content", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "dialog-title" }, [_vm._v("请创建校区")]),
          ]),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "校区名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入校区名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "校区定位", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请在下方定位校区详细地址" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("t-map", {
                    ref: "map",
                    attrs: {
                      width: "600px",
                      height: "360px",
                      location: _vm.form,
                    },
                    on: { location: _vm.locationChange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("创 建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("div", { staticClass: "upload" }, [
        _c("i", { staticClass: "el-icon-plus" }),
      ]),
      _c("div", { staticClass: "owner-name" }, [
        _c("div", { staticClass: "name" }, [_vm._v("创建新的校区")]),
        _c("div", { staticClass: "create-tip" }, [
          _vm._v("一个学校可创建多家校区"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }