var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "page",
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-left": "60px" } },
            [
              _c(
                "el-link",
                {
                  staticClass: "margin-right",
                  attrs: { type: "primary", underline: false },
                  on: { click: _vm.handleLogout },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-back",
                    attrs: { size: "22px" },
                  }),
                  _vm._v("退出登录 "),
                ]
              ),
              _vm.cur !== 1
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary", underline: false },
                      on: { click: _vm.handleBack },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-back",
                        attrs: { size: "22px" },
                      }),
                      _vm._v("返回上一步 "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "step" },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active:
                      !_vm.user.firm_grade_id || _vm.expire_status
                        ? _vm.cur
                        : _vm.cur > 1
                        ? _vm.cur - 1
                        : _vm.cur,
                    "align-center": "",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "进入学校" } }),
                  !_vm.user.firm_grade_id || _vm.expire_status
                    ? _c("el-step", { attrs: { title: "购买套餐" } })
                    : _vm._e(),
                  _vm.multi_pos
                    ? _c("el-step", { attrs: { title: "进入校区" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.cur === 1
            ? _c("firm", {
                attrs: { "multi-pos": _vm.multi_pos },
                on: { next: _vm.handleGrade },
              })
            : _vm._e(),
          _vm.cur === 2 && (!_vm.user.firm_grade_id || _vm.expire_status)
            ? _c("grade", { on: { next: _vm.handleNextPos } })
            : _vm._e(),
          _vm.cur === 3 && _vm.multi_pos ? _c("pos") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }