"use strict";

var _interopRequireDefault = require("D:/www/xinshengdai/xiehui/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = handleClipboard;
var _vue = _interopRequireDefault(require("vue"));
var _clipboard = _interopRequireDefault(require("clipboard"));
function clipboardSuccess() {
  _vue.default.prototype.$message({
    message: 'Copy successfully',
    type: 'success',
    duration: 1500
  });
}
function clipboardError() {
  _vue.default.prototype.$message({
    message: 'Copy failed',
    type: 'error'
  });
}
function handleClipboard(_text, event) {
  var clipboard = new _clipboard.default(event.target, {
    text: function text() {
      return _text;
    }
  });
  clipboard.on('success', function () {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on('error', function () {
    clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
}