"use strict";

var _interopRequireDefault = require("D:/www/xinshengdai/xiehui/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _area = _interopRequireDefault(require("./area"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "TMap",
  data: function data() {
    return {
      visible: true,
      loading: true,
      qq: null,
      map: null,
      mapKey: "2HRBZ-FYPCR-24UWB-WZIFE-JDMJZ-ERBIN",
      mapLevel: 14,
      mapLabel: null,
      mapCity: "",
      mapAddress: "",
      mapLatLng: null,
      searchForm: {
        key: ""
      },
      addressList: [],
      markerList: [],
      markerEventList: [],
      areaJson: []
    };
  },
  props: {
    mapPosition: {
      type: Array
    },
    latitude: {
      type: String
    },
    longitude: {
      type: String
    },
    address: {
      type: String
    }
  },
  created: function created() {
    this.mapLatLng = this.mapPosition;
    this.mapAddress = this.address;
    this.areaJson = _area.default.data;
  },
  mounted: function mounted() {
    var _this = this;
    this.loadMap().then(function (qq) {
      _this.qq = qq;
      _this.initMap();
    });
  },
  methods: {
    loadMap: function loadMap() {
      var mapUrl = "https://map.qq.com/api/js?v=2.exp&key=".concat(this.mapKey, "&callback=onMapCallback");
      return new Promise(function (resolve) {
        // 地图异步加载回调处理
        window.onMapCallback = function () {
          console.log('fuck', qq);
          resolve(qq);
        };
        var scriptLabel = document.createElement("script");
        scriptLabel.setAttribute("type", "text/javascript");
        scriptLabel.setAttribute("src", mapUrl);
        document.body.appendChild(scriptLabel);
      });
    },
    initMap: function initMap() {
      var me = this;
      //初始化地图
      var mapContainer = document.getElementById("mapContainer");
      console.log(mapContainer);
      this.map = new this.qq.maps.Map(mapContainer, {
        zoom: me.mapLevel
      });
      //设置Zoom
      this.qq.maps.event.addListener(this.map, "zoom_changed", function () {
        me.mapLevel = me.map.getZoom();
      });
      //创建Label
      this.mapLabel = new this.qq.maps.Label({
        map: this.map,
        offset: new this.qq.maps.Size(15, -12),
        draggable: false,
        clickable: false
      });
      this.map.setOptions({
        draggableCursor: "crosshair"
      });
      mapContainer.addEventListener("mouseenter", function (e) {
        me.mapLabel.setMap(me.map);
      });
      mapContainer.addEventListener("mouseleave", function (e) {
        me.mapLabel.setMap(null);
      });
      this.qq.maps.event.addListener(this.map, "mousemove", function (e) {
        var latlng = e.latLng;
        me.mapLabel.setPosition(latlng);
        me.mapLabel.setContent(latlng.getLat().toFixed(6) + "," + latlng.getLng().toFixed(6));
      });
      if (this.mapLatLng != null) {
        this.locationService();
      } else {
        this.initcityService();
      }

      //点击获取地址
      this.qq.maps.event.addListener(this.map, "click", function (e) {
        me.$jsonp("http://apis.map.qq.com/ws/geocoder/v1/?location=" + e.latLng.getLat() + "," + e.latLng.getLng() + "&key=" + me.mapKey + "&output=jsonp&callback=?").then(function (res) {
          console.log(res);
          if (res.status == 0) {
            me.mapAddress = res.result != undefined ? res.result.address : "";
            me.mapLatLng = res.result != undefined ? [res.result.location.lat, res.result.location.lng] : null;
          }
        });
      });
    },
    initcityService: function initcityService() {
      var me = this;
      //当位定前位置
      var cityService = new this.qq.maps.CityService({
        complete: function complete(result) {
          console.log(result);
          me.map.setCenter(result.detail.latLng);
          me.mapCity = result.detail.name;
        }
      });
      cityService.searchLocalCity();
    },
    //按坐标获取位置信息
    locationService: function locationService() {
      var _this2 = this;
      var me = this;
      this.$jsonp("http://apis.map.qq.com/ws/geocoder/v1/?location=" + this.mapLatLng[0] + "," + this.mapLatLng[1] + "&key=" + this.mapKey + "&output=jsonp&callback=?").then(function (res) {
        if (res.status == 0 && res.result) {
          var center = new _this2.qq.maps.LatLng(_this2.mapLatLng[0], _this2.mapLatLng[1]);
          _this2.map.panTo(center);
          var marker = new _this2.qq.maps.Marker({
            position: center,
            map: _this2.map
          });
          me.mapAddress = res.result.address;
          me.mapCity = res.result.address_component.city;
        }
      });
    },
    //查询地址信息
    handleSearch: function handleSearch() {
      var _this3 = this;
      function isNullEmpty(value) {
        return value == null || value == 'undefined' || value == [] || (value + '').trim() == '';
      }
      if (!isNullEmpty(this.searchForm.key)) {
        this.$jsonp("http://apis.map.qq.com/ws/place/v1/search?keyword=" + this.searchForm.key + "&boundary=region(" + this.mapCity + ",0)&page_size=9&page_index=1&key=" + this.mapKey + "&output=jsonp&&callback=?").then(function (res) {
          console.log(res);
          if (res.status == 0) {
            res.data.map(function (item, index) {
              item.id = "mapItem" + index;
              item.active = false;
              item.hover = false;
              return item;
            });
            _this3.addressList = res.data;
            _this3.setMarker(res);
            _this3.map.setZoom(14);
          }
        });
      } else {
        this.addressList = [];
        this.$jsonp("http://apis.map.qq.com/ws/geocoder/v1/?region=" + this.mapCity + "&address=" + this.mapCity + "&key=" + this.mapKey + "&output=jsonp&&callback=?").then(function (res) {
          if (res.status == 0) {
            _this3.map.setCenter(new _this3.qq.maps.LatLng(res.result.location.lat, res.result.location.lng));
            _this3.map.setZoom(14);
          }
        });
      }
    },
    //设置Marker
    setMarker: function setMarker(res) {
      var _this4 = this;
      var me = this;
      var latlngBounds = new this.qq.maps.LatLngBounds();
      //删除Marker
      this.markerList.forEach(function (item) {
        item.setMap(null);
      });
      //删除Marker事件
      this.markerEventList.forEach(function (item) {
        _this4.qq.maps.event.removeListener(item);
      });
      this.markerEventList = [];
      this.markerList = [];
      res.data.forEach(function (item, index) {
        var latlng = new _this4.qq.maps.LatLng(item.location.lat, item.location.lng);
        latlngBounds.extend(latlng);
        //创建Marker
        var marker = new _this4.qq.maps.Marker({
          map: _this4.map,
          position: latlng,
          zIndex: 10
        });
        marker.index = index;
        marker.isClicked = false;
        _this4.setAnchor(marker, false);
        _this4.markerList.push(marker);
        //点击事件
        _this4.markerEventList.push(_this4.qq.maps.event.addDomListener(marker, "click", function () {
          me.setFlagClicked(this.index);
        }));
        _this4.markerEventList.push(_this4.qq.maps.event.addDomListener(marker, "mouseover", function () {
          me.setCurrentMarker(this.index, true);
          me.hoverAddress(this.index, true);
          me.mapLabel.setContent(this.position.getLat().toFixed(6) + "," + this.position.getLng().toFixed(6));
          me.mapLabel.setPosition(this.position);
          me.mapLabel.setOptions({
            offset: new this.qq.maps.Size(15, -20)
          });
          document.getElementById("mapItem" + this.index).scrollIntoView({
            behavior: "smooth"
          });
        }));
        _this4.markerEventList.push(_this4.qq.maps.event.addDomListener(marker, "mouseout", function () {
          me.setCurrentMarker(this.index, false);
          me.hoverAddress(this.index, false);
          me.mapLabel.setOptions({
            offset: new this.qq.maps.Size(15, -12)
          });
        }));
        _this4.map.fitBounds(latlngBounds);
      });
      if (this.markerList.length > 0) {
        this.map.setCenter(this.markerList[0].position);
      }
    },
    setAnchor: function setAnchor(marker, flag) {
      var left = marker.index * 27;
      var anchor = new this.qq.maps.Point(10, 30),
        origin = new this.qq.maps.Point(left, flag ? 35 : 0),
        size = new this.qq.maps.Size(27, 33),
        icon = new this.qq.maps.MarkerImage("/images/marker10.png", size, origin, anchor);
      marker.setIcon(icon);
    },
    //选择地址
    selectAddress: function selectAddress(index) {
      this.setCurrentAddress(index);
      this.setFlagClicked(index);
      this.map.setCenter(this.markerList[index].position);
    },
    hoverAddress: function hoverAddress(mapIndex, flag) {
      this.addressList.map(function (item, index) {
        item.hover = flag ? index == mapIndex : flag;
        return item;
      });
    },
    setCurrentAddress: function setCurrentAddress(mapIndex) {
      this.addressList.map(function (item, index) {
        item.active = index == mapIndex;
        return item;
      });
    },
    setCurrentMarker: function setCurrentMarker(index, flag) {
      if (flag || !flag && !this.markerList[index].isClicked) {
        this.setAnchor(this.markerList[index], flag);
      }
    },
    setFlagClicked: function setFlagClicked(mapIndex) {
      var _this5 = this;
      this.markerList.map(function (item, index) {
        if (index == mapIndex) {
          item.isClicked = true;
          item.setZIndex(10);
          _this5.setAnchor(item, true);
          _this5.mapLatLng = [item.getPosition().getLat().toFixed(6), item.getPosition().getLng().toFixed(6)];
          _this5.mapAddress = _this5.addressList[mapIndex].address;
        } else {
          item.isClicked = false;
          item.setZIndex(9);
          _this5.setAnchor(item, false);
        }
        return item;
      });
      this.setCurrentAddress(mapIndex);
      document.getElementById("mapItem" + mapIndex).scrollIntoView({
        behavior: "smooth"
      });
    },
    handleSubmit: function handleSubmit() {
      if (this.mapLatLng == null) {
        this.$message({
          message: "请定位坐标",
          type: "error",
          offset: 60
        });
        return;
      }
      this.$emit("setMap", {
        position: this.mapLatLng,
        address: this.mapAddress
      });
    },
    //关闭
    closeMapWin: function closeMapWin() {
      this.$emit("closeMapWin", {});
    }
  }
};
exports.default = _default;