"use strict";

var _interopRequireDefault = require("D:/www/xinshengdai/xiehui/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkPay = checkPay;
exports.current = current;
exports.getOwner = getOwner;
exports.inviteCode = inviteCode;
exports.join = join;
exports.login = login;
exports.quit = quit;
exports.register = register;
exports.upgradeCode = upgradeCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function getOwner() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/owner',
    method: 'get',
    params: params
  });
}
function register() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/register',
    method: 'get',
    params: params
  });
}
function login() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/login',
    method: 'get',
    params: params
  });
}
function upgradeCode() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/upgradeCode',
    method: 'get',
    params: params
  });
}
function current() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/current',
    method: 'get',
    params: params
  });
}
function join() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/join',
    method: 'get',
    params: params
  });
}
function quit() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/quit',
    method: 'get',
    params: params
  });
}
function inviteCode() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/index/inviteCode',
    method: 'get',
    params: params
  });
}
function checkPay() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/order/checkPay',
    method: 'get',
    params: params
  });
}